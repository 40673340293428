@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Raleway', sans-serif;
    color: #2c2c2c;
  }
}

/* slick slider modify */

.slick-slider .slick-dots li {
  width: 12px;
  height: 12px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}
.slick-slider .slick-dots li button {
  width: 8px;
  height: 8px;
  background: #e1e1e1;
  border-radius: 50%;
  transition: 0.2s;
}
.slick-slider .slick-dots li.slick-active button {
  width: 12px;
  height: 12px;
  background: linear-gradient(270deg, #02a8ea 0%, #0670cb 100%), #c4c4c4;
}
.slick-slider .slick-dots li button::before {
  display: none;
}

.slick-slider .slick-arrow {
  width: 84px;
  height: 54px;
  top: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 999px;
  transform: translateY(-200%);
  z-index: 10;
  color: #fff;
}

.slick-slider .slick-arrow::after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background: transparent no-repeat center;
  margin: auto;
  transition: 0.3s;
}

.slick-slider .slick-arrow:hover {
  background: #fff;
}

.slick-slider .slick-arrow::before {
  display: none;
}

.slick-slider .slick-arrow.slick-prev {
  left: 0;
}

.slick-slider .slick-arrow.slick-prev::after {
  background-image: url('./assets/images/icons/arrow-left-light.svg');
}

.slick-slider .slick-arrow.slick-prev:hover::after {
  background-image: url('./assets/images/icons/arrow-left-dark.svg');
}

.slick-slider .slick-arrow.slick-next {
  right: 0;
}

.slick-slider .slick-arrow.slick-next::after {
  background-image: url('./assets/images/icons/arrow-right-light.svg');
}

.slick-slider .slick-arrow.slick-next:hover::after {
  background-image: url('./assets/images/icons/arrow-right-dark.svg');
}
